<template>
    <h1 class="text-info pb-3 pt-4 projectsTitle text-light">Autres technologies avec lesquelles j'ai travaillé</h1>
    <div class="row">
        <div class="col-2 p-5">
            <img class="mx-auto p-2 img-fluid"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png"
                alt="javascript">
        </div>
        <div class="col-2 p-4">
            <img class="mx-auto p-2 img-fluid"
                src="https://www.opeldo.com/public/images/jquery_logo.png"
                alt="jquery">
        </div>
        <div class="col-2 p-4">
            <img class="mx-auto p-2 img-fluid"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Angular_full_color_logo.svg/2048px-Angular_full_color_logo.svg.png"
                alt="angular">
        </div>
        <div class="col-2 p-4 my-auto">
            <img class="mx-auto p-2 img-fluid"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Cisco_logo_blue_2016.svg/2560px-Cisco_logo_blue_2016.svg.png" alt="cisco">
        </div>
        <div class="col-2 p-4 my-auto">
            <img class="mx-auto p-2 img-fluid"
                src="https://my.trocaire.edu/wp-content/uploads/2016/12/pl-sql.png" alt="plsql">
        </div>
        <div class="col-2 p-4 my-auto">
            <img class="my-auto p-4 img-fluid"
                src="https://upload.wikimedia.org/wikipedia/commons/6/6a/Godot_icon.svg" alt="godot">
        </div> 
    </div>
</template>
<script>
export default {
    name: 'TechnologiesComponent',
}
</script>