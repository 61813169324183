<template>
    <b-navbar type="dark" variant="info" class="bg-black text-white fixed-top p-2">
        <b-container>
            <b-nav>
                <b-nav-item class="m-4" @click="scrollToSection('who-am-i')" @mouseover="myHover" @mouseout="unhover">Qui suis-je?</b-nav-item>
                <b-nav-item class="m-4" @click="scrollToSection('projects')" @mouseover="myHover" @mouseout="unhover">Mes projets</b-nav-item>
                <b-nav-item class="m-4" @click="scrollToSection('cv-contact')" @mouseover="myHover" @mouseout="unhover">CV/Contact</b-nav-item>
            </b-nav>
        </b-container>
    </b-navbar>
</template>
  
<script>
export default {
    methods: {
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        },
        myHover(evt) {
            evt.target.classList.toggle('text-info');
        },
        unhover(evt) {
            evt.target.classList.remove('text-info');
        }
    }
}
</script>
