import { createApp } from 'vue'
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faAddressCard, faPersonCircleQuestion, faC, faHashtag, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faReact, faVuejs, faJava, faUnity } from '@fortawesome/free-brands-svg-icons'



library.add(faAddressCard)
library.add(faPersonCircleQuestion)
library.add(faReact)
library.add(faVuejs)
library.add(faJava)
library.add(faUnity)
library.add(faC)
library.add(faEnvelope)
library.add(faHashtag)

createApp(App).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
