<template>
  <NavbarComponent />
  <div class="container-fluid mx-0 pt-5 mt-5" id="who-am-i">
    <div class="slides">
      <div class="row justify-content-center mb-5 p-5">
        <div class="col-7 text-white p-3 backg">
          <h1 class="text-warning divTitle mb-3">Qui suis-je? <font-awesome-icon
              icon="fa-sharp fa-person-circle-question" /></h1>
          <div class="divText text-justify">
            <h2 class="text-content text-sm">Je suis un étudiant en informatique</h2>
            <h2 class="text-content text-sm">au Cégep André-Laurendeau passionné</h2>
            <h2 class="text-content text-sm">par la programmation.</h2>
            <h2 class="text-content text-sm mt-5">Je suis interessé par le développement</h2>
            <h2 class="text-content text-sm">d'applications et veux poursuivre</h2>
            <h2 class="text-content text-sm">mon parcours à l'université en génie.</h2>
          </div>
        </div>
      </div>
      <button class="button-56 mt-5 m-auto divText text-info" @click=scrollToProjects role="button">Mes projets</button>
      <br>
      <br>
      <br>
      <div ref="projects" id="projects" class="mt-5 pt-5">
        <MyProjectsComponent />
      </div>
      <br>
      <br>
      <TechnologiesComponent />
      <br>
      <br>
      <br>
      <h2 class="text-warning projectsTitle text-monospace text-decoration-underline mb-5"><a class="text-warning" href="https://drive.google.com/uc?export=download&id=17HNLRrxfsdc6BEt9E4e-KWQ8JCPKm2oS">télécharger mon cv</a></h2>
      <div id="cv-contact" class="container mt-5 text-light backg p-5 pb-0 pt-0 mt-4">
        <h1 class="text-info pb-3 pt-4 projectsTitle text-light divTitle">Contactez-moi <font-awesome-icon icon="fa-sharp fa-envelope" /></h1>
        <form @submit.prevent="onSubmit" class="p-4 mb-3">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="name" class="form-label">Nom</label>
              <input type="text" class="form-control" v-model="name" id="name" placeholder="Nom" required />
            </div>

            <div class="col-md-6 mb-3">
              <label for="email" class="form-label">Adresse email</label>
              <input type="email" class="form-control" v-model="email" id="email" placeholder="john@doe.com" required />
            </div>
          </div>

          <div class="mb-3">
            <label for="message" class="form-label">Message</label>
            <textarea class="form-control" v-model="message" id="message" placeholder="Entrez votre message"
              required></textarea>
          </div>

          <div class="mb-3">
            <button type="submit" class="button-56 mt-5 m-auto divText text-info">Envoyer</button>
          </div>

          <div v-if="showNotification()" class="mb-3">
            <div :class="`formcarry-message-block fc-${icon} alert alert-dismissible fade show`" role="alert">
              <div class="fc-message-icon"></div>
              <div class="fc-message-content">{{ errorMessage() }}</div>
              <button type="button" class="btn-close" @click="resetStates()" aria-label="Close"></button>
            </div>
          </div>

        </form>
      </div>


    </div>
  </div>
</template>

<script>
import NavbarComponent from './components/NavbarComponent.vue';
import MyProjectsComponent from './components/projets/MyProjectsComponent.vue';
import TechnologiesComponent from './components/projets/TechnologiesComponent.vue';
import { ref } from 'vue';
export default {
  name: 'App',
  setup() {
    const name = ref('');
    const email = ref('');
    const message = ref('');
    const error = ref('');
    const submitted = ref(false);

    const resetStates = () => {
      submitted.value = false;
      error.value = '';
    };

    const resetForm = () => {
      name.value = '';
      email.value = '';
      message.value = '';
    };

    const onSubmit = async () => {
      resetStates();

      try {
        const response = await fetch("https://formcarry.com/s/rwasCnhof7S", {
          method: 'POST',
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ name: name.value, email: email.value, message: message.value })
        });

        const data = await response.json();

        if (data.code === 200) {
          submitted.value = true;
          resetForm();
        } else if (data.code === 422) {
          error.value = data.message;
        } else {
          error.value = data.message;
        }
      } catch (err) {
        error.value = err.message ? err.message : err;
      }
    };

    const showNotification = () => submitted.value || error.value;
    const icon = () => error.value ? 'erreur' : 'succès';
    const errorMessage = () => error.value ? "Votre message n'a pas pu être envoyé" : "Votre message a été envoyé!";

    return {
      name,
      email,
      message,
      onSubmit,
      showNotification,
      icon,
      errorMessage,
      resetStates
    };
  },
  methods: {
    scrollToProjects() {
      const projectsElement = this.$refs.projects;

      if (projectsElement) {
        projectsElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
  components: {
    MyProjectsComponent,
    TechnologiesComponent,
    NavbarComponent
}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
<style>
@import './background.css';
</style>
