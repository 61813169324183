<template>
    <img :src="url" :style="{ height: imageHeight + 'px' }">
    <div class="m-0 p-0 backg container-fluid">
        <h1 class="divTitle text-info pb-3 pt-4 projectsTitle">mes projets</h1>
        <div class="row text-center text-light d-flex pb-2 justify-content-around">
            <div class="col-2">
                <img src="https://cdn.iconscout.com/icon/free/png-256/free-vuejs-1175052.png"
                    :style="{ height: '150px' }" class="p-3 mb-2">
                <img src="https://upload.wikimedia.org/wikipedia/fr/thumb/2/2e/Java_Logo.svg/550px-Java_Logo.svg.png"
                    :style="{ height: '150px' }" class="mb-4">
                <div class="border-top border-bottom border-secondary p-0 m-0 p-3 text-info">E-Commerce</div>
                <div class="m-2 my-3">
                    REST API avec SpringBoot
                    <br>
                    <br>

                    MySQL
                    <br>
                    <br>
                    Développement Agile
                    <br>
                    <br>
                    Bootstrap-Vue
                    <br>
                    <br>
                    Réalisé tout seul
                </div>
                <div class="border-top border-bottom border-secondary p-0 m-0 p-3"><a
                        href="https://github.com/yskuridov/E-Commerce-using-Vue.js-and-Spring-Boot">Github</a></div>
            </div>
            <div class="col-2">
                <img src="https://cdn.icon-icons.com/icons2/2415/PNG/512/react_original_wordmark_logo_icon_146375.png"
                    :style="{ height: '150px' }" class="p-3 mb-2">
                <img src="https://upload.wikimedia.org/wikipedia/fr/thumb/2/2e/Java_Logo.svg/550px-Java_Logo.svg.png"
                    :style="{ height: '150px' }" class="mb-4">
                <div class="border-top border-bottom border-secondary p-0 m-0 p-3 text-info">Gestion de stages
                </div>
                <div class="m-2 my-3">
                    REST API avec SpringBoot
                    <br>
                    <br>
                    PostgreSQL
                    <br>
                    <br>
                    Spring Security
                    <br>
                    <br>
                    Déploiement avec Azure
                    <br>
                    <br>
                    Réalisé en équipe (SCRUM)
                </div>
                <div class="border-top border-bottom border-secondary p-0 m-0 p-3"><a
                        href="https://github.com/baie-st-paul/THE-Tp">Github</a></div>
            </div>
            <div class="col-2">
                <img src="https://web-strapi.mrmilu.com/uploads/flutter_logo_470e9f7491.png"
                    :style="{ height: '150px' }" class="p-3 mb-2">
                <img src="https://firebase.google.com/static/downloads/brand-guidelines/PNG/logo-vertical.png"
                    :style="{ height: '150px' }" class="p-1 mb-4">
                <div class="border-top border-bottom border-secondary p-0 m-0 p-3 text-info">Gestion d'épiceries</div>
                <div class="m-2 my-3">
                    REST API avec Flutter
                    <br>
                    <br>
                    NoSQL (Firebase)
                    <br>
                    <br>
                    Déployé sur Google Play
                    <br>
                    <br>
                    API externe CUP Code
                    <br>
                    <br>
                    Réalisé tout seul
                </div>
                <div class="border-top border-bottom border-secondary p-0 m-0 p-3"><a
                        href="https://github.com/yskuridov/grocery_app_flutter">Github</a></div>
            </div>
            <div class="col-2">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png"
                    :style="{ height: '150px' }" class="p-3 mb-2">
                <img src="https://upload.wikimedia.org/wikipedia/fr/thumb/3/3b/Raspberry_Pi_logo.svg/810px-Raspberry_Pi_logo.svg.png"
                    :style="{ height: '150px' }" class="p-2 mb-4">
                <div class="border-top border-bottom border-secondary p-0 p-3 text-info">Contrôlleur d'objets</div>
                <div class="m-2 my-3">
                    Composantes Raspberry Pi
                    <br>
                    <br>
                    Tensorflow et Lobe.ai
                    <br>
                    <br>
                    Flask et Django
                    <br>
                    <br>
                    RPi.GPIO et Gpiozero
                    <br>
                    <br>
                    Réalisé tout seul
                </div>
                <div class="border-top border-bottom border-secondary p-0 m-0 p-3"><a
                        href="https://github.com/yskuridov/rasp_interface_python">Github</a></div>
            </div>
            <div class="col-2">
                <img src="https://i.redd.it/tu3gt6ysfxq71.png" :style="{ height: '150px' }" class="p-2 mb-2">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Logo_C_sharp.svg/1200px-Logo_C_sharp.svg.png" :style="{ height: '150px' }" class="p-3 mb-4">
                <div class="border-top border-bottom border-secondary p-0 m-0 p-3 text-info">Jeu vidéo</div>
                <div class="m-2 my-3">
                    Jeu FPS créé avec Unity
                    <br>
                    <br>
                    Animations et effets
                    <br>
                    <br>
                    Gameplay codé en C#
                    <br>
                    <br>
                    IA de l'ennemi
                    <br>
                    <br>
                    Réalisé en équipe
                </div>
                <div class="border-top text-info border-bottom border-secondary p-0 m-0 p-3">Github sur demande</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyProjectsComponent',
}
</script>

<style>div a {
    text-decoration: none;
}</style>